import { ReserveItemDetailsDto } from 'types/dtos'
import { ReserveItemDetailsModel } from 'types/models'
import { transformItemPhotoDtos } from 'data/transformers/item'

export const transformReserveItemDetailsDto = (
  dto: ReserveItemDetailsDto,
): ReserveItemDetailsModel => ({
  id: dto.id,
  title: dto.title,
  photos: transformItemPhotoDtos(dto.photos),
})
