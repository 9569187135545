import { VasEntryPointDto } from 'types/dtos'
import { VasEntryPointModel } from 'types/models'

export const transformVasEntryPoint = (dto: VasEntryPointDto): VasEntryPointModel => ({
  name: dto.name,
  title: dto.title,
  subtitle: dto.subtitle,
  badgeTitle: dto.badge_title,
  badgeSubtitle: dto.badge_subtitle,
  buttonTitle: dto.button_title,
  locked: dto.locked,
  lockedNote: dto.locked_note,
  bumpableItemCount: dto.bumpable_item_count,
  selectedItemId: dto.selected_item,
})

export const transformVasEntryPoints = (dtos: Array<VasEntryPointDto>): Array<VasEntryPointModel> =>
  dtos.map(transformVasEntryPoint)
