import { MarkAsSoldItemDetailsDto } from 'types/dtos'
import { MarkAsSoldItemDetailsModel } from 'types/models'
import { transformItemPhotoDtos } from 'data/transformers/item'

import { transformCurrencyAmountDto } from './currency-amount'

export const transformMarkAsSoldItemDetailsDto = (
  dto: MarkAsSoldItemDetailsDto,
): MarkAsSoldItemDetailsModel => ({
  id: dto.id,
  title: dto.title,
  photos: transformItemPhotoDtos(dto.photos),
  price: transformCurrencyAmountDto(dto.price),
})
