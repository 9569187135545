import { PromoBoxType } from '_libs/common/braze/constants'
import { PromoBoxDto } from 'types/dtos'
import { GenericPromoBoxModel } from 'types/models'

export const transformPromoBoxDto = (dto: PromoBoxDto): GenericPromoBoxModel => ({
  id: dto.id.toString(),
  url: dto.web_link || '',
  backgroundColor: dto.photo.dominant_color,
  imageUrl: dto.photo.url,
  impressionUrl: dto.impression_url,
  isControl: false,
  type: PromoBoxType.Vinted,
  position: undefined,
})
